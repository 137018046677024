<template>
    <div>
        <MessageBox
            v-if="showMessageBox"
            ref="messageBox" :message="message" @close="handleClose"
            />
    <div class="container">
        <b-card class="p-3">
        <h2>Verify OTP</h2>
        <b-form @submit.prevent="verifyOTP">
            <b-form-group
            label="Email address:"
            label-for="email"
            description="Enter your email address"
            >
            <b-form-input
                id="email"
                v-model="email"
                type="email"
                required
                placeholder="Enter email"
            ></b-form-input>
            </b-form-group>

            <b-form-group
            label="OTP:"
            label-for="otp"
            description="Enter the OTP you received"
            >
            <b-form-input
                id="otp"
                v-model="otp"
                type="text"
                required
                placeholder="Enter OTP"
            ></b-form-input>
            </b-form-group>

            <div class="text-right">
            <b-button type="submit" variant="primary" class="mt-3">Verify OTP</b-button>
            </div>
        </b-form>
        </b-card>
    </div>
</div>
</template>

    
<script>
    import axios from 'axios';
    import MessageBox from './MessageBox.vue';
    import { encode } from 'base-64';

    export default {
        name: 'JoinStaffMember',
        components: {
            MessageBox
        },
        data() {
            return {
                showMessageBox: true,
                email: '',
                otp: '',
                message: '',
            };
        },
    methods: {
        handleClose() {
            if (this.email) {
                this.$router.push({
                        name: 'UserRegisterComplete',
                        query: { addStaff: true, employee: encode(this.email) }
                        });
            }
            console.log("Closed handled.")
            // this.$router.push('/target-page'); // Perform redirection
        },
        async verifyOTP() {
            try {
                    const response = await axios.post('/otp/verify/', {
                        email: this.email,
                        otp: this.otp
                    });
                
                    if (response.status === 200) {
                        this.message = `OTP Verified for ${this.email}. Set new password on the next page`
                        this.$refs.messageBox.show();
                    }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // console.log(error.response.data.detail);
                    this.message = error.response.data.detail
                    
                }
                else {
                    this.message = "We apologize, we cannot not reach service to generate the OTP. Please try again later.";
                }
                this.$refs.messageBox.show();
            }
        }
    },
};
</script>
