<template>
  <div>
    <MessageBox ref="messageBox" :message="message" />
    <div class="container">
      <h2 v-if="addStaff">Add Staff Member to '{{ orgName }}'</h2>
      <h2 v-else>Register</h2>
      <div class="form-group">

        <form @submit.prevent="handleSubmit">

        <br v-if="addStaff" />
        <div v-if="addStaff" class="row">
            <div class="col">
              <label for="dateOfJoining">Date of Joining: &nbsp; </label>
              <input
                type="date"
                id="dateOfJoining"
                v-model="dateOfJoining"
                required
              />
            </div>
        </div>
        <br v-if="addStaff" />

        <div class="row">
          <div class="col">
            <label for="email">Email:</label>
            <input
              type="email"
              id="email"
              v-model="email"
              required
              class="form-control"
              placeholder="Enter email"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button type="submit">{{ addStaff ? 'Generate OTP' : 'Register' }}</button>
          </div>
        </div>
      </form>
    </div>
    </div>
    <!-- Popup for Add Staff -->
    <div v-if="showPopup" class="popup">
      <h4>Take a photo or screenshot of text in red box below and share with the staff you want to add to your organisation.</h4>
      <div class="popup-inner">
        <div class="info-row">
          <label>Email:</label>
          <span>{{ email }}</span>
        </div>
        <div class="info-row">
          <label>OTP:</label>
          <span>{{ otp }}</span>
        </div>
        <div class="info-row">
          <label>Link:</label>
          <span>{{ joinUrl }}</span>
        </div>
        <p>Open the link above and fill in your email and OTP to join.</p>
        <p>The OTP will expire in {{ expire_duration }} hour.</p>
      </div>
      
      <button @click="closePopup">Close</button>
    </div>
  </div>
</template>
  
<script>
  import axios from '@/axios.js';
  import MessageBox from './MessageBox.vue';
  import { decode } from 'base-64';
  
  export default {
    name: 'UserRegister',
    props: {
        org: {
            type: String,
            default: null, // Default value if no org is passed
        },
        addStaff: {
          type: Boolean,
          default: false
        }
    },
    mounted() {
        // Decode the parameter
        const decoded = decodeURIComponent(this.org);
        this.decodeOrg(decoded)
    },
    components: {
      MessageBox
    },
    data() {
      return {
        dateOfJoining: "",
        expire_duration: 1, // 1 hour
        email: "",
        message: "",
        orgName: "",
        orgId: "",
        otp: "",
        showPopup: false,
      };
    },
    methods: {
      decodeOrg(){
        const orgParam = decode(this.org);
        const org = orgParam.split(':');
        this.orgName = org[0];
        this.orgId = org[1];
      },
      handleSubmit() {
        // Determine which function to call based on `addStaff`
        if (this.addStaff) {
          this.addStaffMember();
        } else {
          this.register();
        }
      },
      async addStaffMember() {
        try {
          if (!this.orgId) {
              alert("Organization is required!");
              return;
          }
          const now = new Date();
          now.setHours(now.getHours() + this.expire_duration);
          const expiry_date = now.toISOString();
          const response = await axios.post(
            "/otp/generate",
            {
              "email": this.email,
              "organisation_id": this.orgId,
              "expiry_date": expiry_date
            });
          const baseUrl = `${window.location.protocol}//${window.location.host}`;

          this.joinUrl = `${baseUrl}/join`
          this.otp = response.data.otp;
          this.showPopup = true;
        } catch (error) {
            // console.error("Error adding staff member:", error);
            if (error.response && error.response.status === 401) {
              alert("You are not authorized. Please log in and try again.");
            } else {
              alert("Failed to generate OTP. Please try again.");
            }
          }
      },
      async register() {
        try {
          const response = await axios.post('/authorization/verify', {
            email: [this.email],
          });
          this.setEmailCookie(this.email);
          this.message = 'Registration email sent successfully!'
          
          this.$refs.messageBox.show();
          this.$router.push({
            name: 'EmailSentNotify',
            query: { registerEmail: this.email }
        });
        } catch (error) {
          if (error.response && error.response.status === 409) {
              this.message = error.response.data.detail
              this.$refs.messageBox.show();
            } else {
              console.error(error);
              this.message = 'Failed to send registration email.';
              this.$refs.messageBox.show();
          }

        }
      },
      closePopup() {
        this.showPopup = false;
      },
      setEmailCookie(email) {
        // Set the cookie to expire in 7 days
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);

        // Create the cookie string
        const cookieString = `registrationEmail=${email}; expires=${expirationDate.toUTCString()}; path=/`;

        // Set the cookie
        document.cookie = cookieString;
      }
    }
  };
  </script>
  
  <style>
.row {
  margin-bottom: 1em;
}
.col {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
button {
  padding: 0.5em 1em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25vw;
  min-width: 300px;
  border-radius: 15px;
  border: 2px solid blue;
  background: linear-gradient(to right, #f0f8ff, #e6f7ff);
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.popup-inner {
  border: 1px solid red;
  padding: 10px;
  margin-bottom: 15px;
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.info-row label {
  font-weight: bold;
  margin-right: 10px;
}

.info-row span {
  word-break: break-all;
  flex: 1;
}

h3 {
  margin-top: 0;
  margin-bottom: 15px;
}

button {
  display: block;
  margin-top: 15px;
}



</style>