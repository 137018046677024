<template>
    <div v-show="visible" class="message-box">
        <div v-for="(sentence, index) in formattedMessage" :key="index">
            <p>{{ sentence }}</p>
        </div>
        <button
            @click="close"
            @mouseover="hovering = true"
            @mouseleave="hovering = false"
        >
            {{ buttonText }}
        </button>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            default: "",
        },
        duration: {
            type: Number,
            default: 5000 // Default duration of 5 seconds
        }
    },
    data() {
        return {
            visible: false,
            remainingTime: 5, // Initial countdown value in seconds
            timer: null,
            hovering: false // Tracks whether the button is being hovered
        };
    },
    computed: {
        formattedMessage() {
            return this.message
                .split(". ")
                .filter(sentence => sentence.trim() !== "")
                .map(sentence => sentence.trim() + ".");
        },
        buttonText() {
            if (this.hovering) {
                return "Close now";
            }
            return `Closing in ${this.remainingTime} second${this.remainingTime > 1 ? "s" : ""}`;
        }
    },
    methods: {
        show() {
            this.visible = true;
            this.remainingTime = Math.ceil(this.duration / 1000); // Calculate countdown from duration
            this.startCountdown();
        },
        close() {
            this.stopCountdown();
            this.visible = false;
            this.$emit('close');

        },
        startCountdown() {
            this.stopCountdown(); // Clear any existing timer
            this.timer = setInterval(() => {
                if (this.remainingTime > 0) {
                    this.remainingTime -= 1;
                } else {
                    this.close();
                }
            }, 1000); // Decrement every second
        },
        stopCountdown() {
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
            }
        }
    },
    beforeDestroy() {
        this.stopCountdown();
    }
};
</script>

<style>
.message-box {
    position: fixed;
    background-color: #f0f0f0;
    top: 50%;
    left: 50%;
    padding: 20px;
    transform: translate(-50%, -50%);
    z-index: 9999;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center; /* Ensures text and elements are aligned center */
}

button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: block;
    margin-left: auto;
    margin-right: auto; /* Centers the button */
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}
</style>
