<template>
<div v-if="showConsentMessage" class="consent-message">
    <p>We use cookies and similar technologies to enhance your experience. By clicking "Accept" or continuing to use our site, you consent to the use of these technologies.</p>
    <button @click="acceptConsent">Accept</button>
</div>
</template>

<script>
import axiosInstance from 'axios';
import { mapActions } from 'vuex';
import axios from '@/axios.js';

export default {
data() {
    return {
        showConsentMessage: !this.isConsentGiven()
    };
},
mounted() {
    if (this.showConsentMessage) {
        this.collectAndStoreSystemData();
    }
},
methods: {
    ...mapActions(['getCookie', 'generateRandomString',]),
    collectAndStoreSystemData() {
    const systemData = {
        screen_res: this.getScreenResolution(),
        public_ip_address: this.getIPAddress(),
        device_os: this.getOperatingSystem(),
        browser: this.getBrowserInfo(),
        user_session_id: this.getVisitorIdentifier(),
    };
    document.cookie = `systemData=${JSON.stringify(systemData)};path=/;`;
    },
    getScreenResolution() {
        return `${window.screen.width}x${window.screen.height}`;
    },
    async getIPAddress() {
        return null
        // try {
        // const response = await axiosInstance.get('https://api.ipify.org/?format=json');
        // return response.data.ip;
        // } catch (error) {
        //     return null;
        // }
    },
    async acceptConsent() {
        this.showConsentMessage = false;
        localStorage.setItem('userConsent', 'true');
        const ipAddress = await this.getIPAddress();
        const visitorIdentifier = await this.getVisitorIdentifier()
        this.setCookie('visitorIdentifier', visitorIdentifier)

        const consentData = {
            screen_res: this.getScreenResolution(),
            public_ip_address: ipAddress || "",
            device_os: this.getOperatingSystem(),
            browser: this.getBrowserInfo(),
            user_accept_consent: true,
            user_session_id: visitorIdentifier,
        };
        axios.post('/profile/consent', consentData);
    },
    getOperatingSystem() {
        return navigator.platform;
    },
    setCookie(key, value) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);
        const cookieString = `${key}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
        document.cookie = cookieString;
    },
    getBrowserInfo() {
        const userAgent = navigator.userAgent;

        const browsers = [
            { name: "Brave", test: /Brave/ },
            { name: "DuckDuckGo", test: /DuckDuckGo/ },
            { name: "Firefox", test: /Firefox/ },
            { name: "Chrome", test: /Chrome/ },
            { name: "Safari", test: /Safari/ },
            { name: "Edge", test: /Edge/ },
            { name: "Opera", test: /Opera|OPR/ },
        ];

        for (let browser of browsers) {
            if (browser.test.test(userAgent)) {
            const versionMatch = userAgent.match(new RegExp(`${browser.name}[\\/\\s](\\d+)`));
            const version = versionMatch ? versionMatch[1] : 'Unknown Version';
            return `${browser.name} ${version}`;
            }
        }
        return 'Unknown Browser';
    },
    async getVisitorIdentifier() {
        const name = "visitorIdentifier=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i].trim();
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
        // cookie was not found, so create a new return a random string to sent visitor identifier.
        const randomString = await this.generateRandomString(10);
        console.log("Cookie was not found so randomeString: ", randomString)
        return randomString;
    },
    isConsentGiven() {
        return localStorage.getItem('userConsent') === 'true';
    }
}
}
</script>

<style>
.consent-message {
position: fixed;
bottom: 0;
left: 0;
width: 100%;
background-color: #f8f9fa;
padding: 15px;
box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
text-align: center;
}
.consent-message button {
margin: 0 10px;
}
</style>
