import Vue from "vue"
import Vuex from "vuex"
import axios from '@/axios.js';
import { encode, decode } from 'base-64';
import router from '../router'


Vue.use(Vuex);

const state = {
    organisation: null,
    auth: {
        isLoggedIn: false,
        userEmail: ''
    },
    subscriptionPlans: [],
    organisations: [],
    visitorIdentifier: '',
    selectedOrganisation: null
}

const getters = {
    isLoggedIn: state => state.auth.isLoggedIn,
    userEmail: state => state.auth.userEmail,
    subscriptionPlans: state => state.subscriptionPlans,
    organisations: state => state.organisations,
    currentError: state => state.error,
    selectedOrganisation: state => state.selectedOrganisation,
    organisation: state => state.organisation
};

const actions = {
    getCookie(context, name) {
        const cookieArr = document.cookie.split(';');
        for (let cookie of cookieArr) {
            let [cookieName, cookieValue] = cookie.split('=');
            cookieName = cookieName.trim();
            if (cookieName === name) {
                return decodeURIComponent(cookieValue);
            }
        }
        return '';
    },
    async setCookie({commit, dispatch}, {orgName, orgId, index}){
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);
        const combined = `${orgName}:${orgId}:${index}`;
        const hash = encode(combined);
        const cookieString  = `__selected_org_hash=${hash}; expires=${expirationDate.toUTCString()}: path=/`;
        document.cookie = cookieString;

        // Now call initializeOrganisation to set the store state based on the updated cookie
        await dispatch('initializeOrganisation');
    },
    generateRandomString({ commit }, length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },
    async getOrganisation({ dispatch }) {
        const encodedCookie = await dispatch('getCookie', '__selected_org_hash');
        if (encodedCookie) {
            const decodedValue = decode(encodedCookie);
            const [name, id, index] = decodedValue.split(':');
            return { name, id, index };
        }
        return { name: '', id: '', index: '' };
    },
    // Initialize organisation on startup
    async initializeOrganisation({ commit, dispatch }) {
        const organisation = await dispatch('getOrganisation');
        commit('SET_ORGANISATION', organisation);
    },
    logoutAction({ commit }) {
        commit('SET_LOGOUT');
    },
    selectOrganisation({ commit }, organisation) {
        commit('SET_SELECTED_ORGANISATION', organisation);
    },
    setEmailCookie(email) {
        // Set the cookie to expire in 7 days
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);
        const cookieString = `registrationEmail=${email}; expires=${expirationDate.toUTCString()}; path=/`;
        document.cookie = cookieString;
    },
    async checkAuthentication({ commit, dispatch }) {
        try {
            const response = await axios.get('/authorization/');
            if (response.data.detail === "You are not logged in.") {
                commit('SET_AUTH', { isLoggedIn: false });
                return false
            }
            const authenticatedUser = response.data["user"];
            const registrationEmail = await dispatch('getCookie', 'registrationEmail');
            if (authenticatedUser) {
                    commit('SET_AUTH', { isLoggedIn: true });
                
                if (authenticatedUser === registrationEmail) {
                    commit('SET_AUTH', { isLoggedIn: true, userEmail: authenticatedUser });
                    return true;
                }
                else {
                    localStorage.removeItem('registrationEmail');
                    setEmailCookie(authenticatedUser)
                }
            }
        } catch (error) {
            console.error('Authentication check failed:', error);
            commit('SET_AUTH', { isLoggedIn: false, userEmail: '' });
        }
    },
    async logoutAction({ commit, state }) {
        try {
            console.log(`${axios.defaults.baseURL}/authorization/logout`);
            // Call to backend logout endpoint
            await axios.post('/authorization/logout');

            // Upon successful response, update the Vuex state to log the user out
            commit('SET_AUTH', { isLoggedIn: false });
            localStorage.removeItem('registrationEmail');
            router.push('/login');
        } catch (error) {
            console.error("Error occured during logout:", error);
        }
    },
    async fetchOrganisations( {commit, dispatch} ) {
        this.loading = true;
        this.error = null;
        try {
                const resp = await axios.get('/organisation/user/');
                commit('SET_EXISTING_ORGS', resp.data)
                const organisationFromCookie = dispatch('getOrganisation');
                commit('SET_SELECTED_ORGANISATION', organisationFromCookie);
            } catch (err) {
                console.error('Error getting organisations:', err);
                commit('SET_EXISTING_ORGS', [])
        } finally {
            this.loading = false;
        }
    },
    async fetchSubscriptionPlans({ commit, state }) {
        try {
                const response = await axios.get('/organisation/subscription/plans');
                commit('SET_SUBSCRIPTION_PLANS', response.data);
            } catch (error) {
            console.error('Failed to get subscription plans:', error);
            }
        },
    async addOrganisation({ commit, state }, organisationData) {
        try {
            const response = await axios.post('/organisation/add/', organisationData);
            commit('ADD_ORGANISATION', response.data);
            return response.data;
        } catch (error) {
            console.error('Failed to add an organisation:', error);
            throw error;
        }
    },
};

const mutations = {
    SET_AUTH(state, { isLoggedIn, userEmail }) {
    state.auth.isLoggedIn = isLoggedIn;
    state.auth.userEmail = userEmail;
    },
    SET_ORGANISATION(state, organisation) {
        // this mutation is related to getting the stored organisation name and id from cookie
        state.organisation = organisation;
    },
    SET_LOGOUT(state) {
        state.isLoggedIn = false;
        state.userEmail = '';
        state.organisations = [];
    },
    SET_EXISTING_ORGS(state, organisations){
        state.organisations = organisations;
    },
    SET_SUBSCRIPTION_PLANS(state, plans) {
    state.subscriptionPlans = plans;
    },
    ADD_ORGANISATION(state, organisation) {
    state.organisations.push(organisation);
    },
    SET_SELECTED_ORGANISATION(state, organisation) {
        state.selectedOrganisation = organisation;
    }
};


// store configurations object is passed to Vuex.Store
const store =  new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})

// Initialize organisation state
store.dispatch('getOrganisation').then(org => {
    store.commit('SET_ORGANISATION', org);
})


export default store;
