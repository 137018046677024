import Vue from 'vue';
import Router from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router'
import UserRegister from '../components/UserRegister.vue';
import UserRegisterComplete from '../components/UserRegisterComplete.vue';
import UserLogin from '../components/UserLogin.vue';
import WelcomeUser from '@/components/WelcomeUser.vue';
import UserAddOrganisation from '@/components/UserAddOrganisation.vue';
import StockMedicine from '../components/StockMedicine.vue';
import ViewMedicines from '@/components/ViewMedicines.vue';
import EmailSentNotify from '../components/EmailSentNotify.vue';
import JoinStaffMember from '@/components/JoinStaffMember.vue';
import store from '../store/store';
import Home from '../components/HomeView.vue'


Vue.use(Router);

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    redirect: '/home'
  },  
  {
    path: '/register:org?',
    name: 'UserRegister',
    component: UserRegister,
    props: true,
  },
  {
    path: '/emailsent/:registerEmail?',
    name: 'EmailSentNotify',
    component: EmailSentNotify
  },
  {
    path: '/complete',
    name: 'UserRegisterComplete',
    component: UserRegisterComplete
  },
  {
    path: '/login',
    name: 'UserLogin',
    component: UserLogin
  },
  {
    path: '/join',
    name: 'JoinStaffMember',
    component: JoinStaffMember
  },
  {
    path: '/welcome',
    name: 'WelcomeUser',
    component: WelcomeUser
  },
  {
    path: '/add-organisation',
    name: 'AddOrganisation',
    component: UserAddOrganisation
  },
  {
    path: '/medicine',
    name: 'StockMedicine',
    component: StockMedicine
  },
  {
    path: '/medicines',
    name: 'ViewMedicines',
    component: ViewMedicines,
  }
];

const router = new Router({
  mode: 'history',
  routes
});

export default router;
